import { QueryClient } from '@tanstack/react-query';
import { LDContext } from 'launchdarkly-js-sdk-common';

import { updateFeatureFlagContext } from '~/src/common/services/FeatureFlag';
import { TagManager } from '~/src/common/services/TagManager';
import Tracker from '~/src/common/services/Tracker';
import { PostApiAuthMe200 } from '~/src/queries/api-ecom/generated/api-ecom.schemas';
import { addCountryCode } from '~/src/screens/AccountManagementModal/utils';
import { QUERY_KEYS } from '~/src/screens/App/queries/queries';

export const handleSignoutSuccess = (queryClient: QueryClient) => {
  queryClient.setQueryData(QUERY_KEYS.user, {});

  Tracker.sendEvent('logged out');

  window.location.assign('/');
};

export const onSuccessAuthMe = (data: PostApiAuthMe200) => {
  TagManager.setDataLayer({
    email: data.email?.trim().toLowerCase(),
    userId: data.id,
    phone: addCountryCode(data.phone),
  });

  // eslint-disable-next-line @typescript-eslint/no-floating-promises  -- auto-ignored when updating eslint
  updateFeatureFlagContext((currentContext: LDContext) => ({
    ...currentContext,
    user: {
      key: data.id,
      email: data.email,
    },
  }));
};

export const onErrorAuthMe = () => {
  TagManager.setDataLayer({ email: '', userId: '', phone: '' });
};
